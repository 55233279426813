<template>
  <section class="index-hero">
    <div class="container index-hero-slider-container">
      <div class="index-hero__slider swiper">
        <swiper
          ref="swiperRef"
          @slideChange="updateCurrentSlide"
          class="mySwiper"
          :options="swiperOption"
        >
          <swiper-slide v-for="banner in banners" :key="banner.id">
            <a
              :href="
                banner.attributes.link ||
                'https://magnumretail.uz/ru/?city_id=1'
              "
              class="index-hero__slide swiper-slide"
              :style="{ backgroundImage: `url(${getBannerImage(banner)})` }"
            >
              <a
                class="index-hero__slide-link"
                :href="
                  banner.attributes.link ||
                  'https://magnumretail.uz/ru/?city_id=1'
                "
                >{{ $t("more") }}</a
              >

              <a target="_blank" rel="noopener noreferrer">
                <div class="index-hero__content">
                  <div :style="getTitleColor(banner)" class="index-hero__title">
                    {{ banner.attributes.header }}
                  </div>
                  <div
                    :style="getTitleColor(banner)"
                    class="index-hero__subtitle"
                  >
                    {{ banner.attributes.sub_header }}
                  </div>
                </div>
              </a>
            </a>
          </swiper-slide>
        </swiper>
        <div @click="prevSlide" class="swiper-button-prev"></div>
        <div @click="nextSlide" class="swiper-button-next"></div>
        <div class="slider-pagination-wrapper">
          <div class="pagination__custom"></div>
          <span class="swiper-counts"
            >{{ currentSlide }} / {{ slidesTotalCount }}</span
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getBanners } from "@/api";

export default {
  name: "HomeBannerSlider",
  components: {},
  data() {
    return {
      banners: null,
      slidesTotalCount: 0,
      currentSlide: 1,
      swiperOption: {
        lazy: true,
        slideTo: 1,
        slidesPerView: 1,
        spaceBetween: 20,
        initialSlide: 1,
        centeredSlides: false,
        loop: true,
        responsive: true,
        pagination: {
          el: ".pagination__custom",
          clickable: true,
          renderBullet: function (index, className) {
            return `<div class="wrapper ${className}">
              <div class="pie spinner"></div>
              <div class="pie filler"></div>
              <div class="mask"></div>
            </div>`;
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  watch: {
    selectedCity: {
      handler() {
        this.loadBanners();
      },
    },
  },
  created() {
    this.loadBanners();

    if (this.$isMobile()) {
      this.swiperOption.slidesPerView = 1;
    }
  },
  mounted() {},

  methods: {
    loadBanners() {
      this.banners = null;

      getBanners().then((banners) => {
        this.banners = banners;
        this.slidesTotalCount = this.banners.length;
      });
    },
    updateCurrentSlide() {
      this.currentSlide = this.swiper?.swiperInstance.realIndex + 1;
    },
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
    getBannerImage(banner) {
      const placeHolderImageUrl =
        "https://placeholder.pics/svg/288x380/B7FF7D/6C6D73-79E858/no%20moblie%20image";
      if (this.$isMobile()) {
        return banner.attributes.image_mob?.data?.attributes?.url
          ? this.$helpers.getAbsolutePath(
              banner.attributes.image_mob?.data?.attributes?.url
            )
          : placeHolderImageUrl;
      }
      return this.$helpers.getAbsolutePath(
        banner.attributes.image?.data?.attributes?.url
      );
    },
    getTitleColor(item) {
      return "color:" + item?.attributes?.left_side_text_color;
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-next,
.swiper-button-prev {
  left: auto;
  right: -33px;
}
.swiper {
  height: 550px;
  background-color: #f9f9f9;
  border-radius: 15px;
}

@media (max-width: 680px) {
  .swiper {
    height: auto;
  }
}

@media (max-width: 450px) {
  .swiper {
    height: 390px;
  }
}

@media (max-width: 360px) {
  .swiper {
    height: 380px;
  }
}
</style>
